<template>
    <div>
        <Loading v-if="$global.state.loading" />
        <div v-if="order != null">
            <v-card class="shadow pa-4">
                <div class="d-flex justify-space-between mb-2">
                    <v-spacer></v-spacer>
                    <v-btn @click="orderStatusDialog = true" class="btn py-4" small dark v-if="order.status == 0" color="primary lighten-1 secondary--text">طلبية جديدة</v-btn>
                    <v-btn @click="orderStatusDialog = true" class="btn py-4" small dark v-if="order.status == 1" color="orange lighten-2">قيد التصميم</v-btn>
                    <v-btn @click="orderStatusDialog = true" class="btn py-4" small dark v-if="order.status == 2" color="indigo lighten-2">قيد الطباعة</v-btn>
                    <v-btn @click="orderStatusDialog = true" class="btn py-4" small dark v-if="order.status == 3" color="info">قيد التوصيل</v-btn>
                    <v-btn @click="orderStatusDialog = true" class="btn py-4" small dark v-if="order.status == 4" color="grey lighten-2">منجزة</v-btn>
                    <v-btn @click="orderStatusDialog = true" class="btn py-4" small dark v-if="order.status == 5" color="error">ملغاة</v-btn>
                    <v-btn depressed class="btn py-4 mr-2" small @click="startChat">
                        <v-icon color="green" small>fi fi-rr-envelope</v-icon>
                    </v-btn>
                </div>

                <v-card outlined class="pa-2">
                    <div class="px-4">
                        <h5> 
                            <span class="grey--text ml-2">رقم الطلبية : </span>
                            <v-chip small label>{{ order.orderNo }}</v-chip> 
                        </h5>
                        <h5 class="mt-1">
                            <span class="grey--text ml-2">اسم الطلبية : </span>
                            <span v-text="order.orderName"></span>
                        </h5>
                        <h5 class="mt-1">
                            <span class="grey--text ml-2">تاريخ الطلبية : </span>
                            <span> {{ order.created | formatDate }} </span>
                        </h5>
                        <h5 class="mt-1">
                            <span class="grey--text ml-2"> الزبون : </span>
                            <span v-text="order.fromUserFullName"></span>
                        </h5>

                        <div class="pt-1">
                            <span class="grey--text">عنوان التوصيل :</span>
                            {{ order.deliveryAddress }}
                        </div>

                        <div class="pt-1">
                            <span class="grey--text">هاتف الزبون :</span>
                            {{ order.recipientPhoneNo1 }}
                        </div>

                        <div class="pt-1" v-if="order.recipientPhoneNo2">
                            <span class="grey--text">هاتف الزبون :</span>
                            {{ order.recipientPhoneNo2 }}
                        </div>

                        <div class="pt-1">
                            <span class="grey--text">تاريخ انشاء الطلبية :</span>
                            {{ order.created | formatDate }}
                        </div>

                        <div class="pt-1">
                            <span class="grey--text">ملاحظات :</span>
                            {{ order.note ? order.note : "لا توجد" }}
                        </div>

                        <div v-if="order.imageUrl" class="mt-2">
                            <div class="img__wrap">
                                <v-img
                                    @click="openInNewTab(order.imageUrl)"
                                    aspect-ratio
                                    :src="order.imageUrl"
                                    width="100"
                                ></v-img>
                                <p class="img__description">اضغط لفتح الصورة</p>
                            </div>
                        </div>

                        <!-- products -->
                        <v-card flat outlined class="mt-3">
                            <v-simple-table>
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>القياس</th>
                                        <th>صورة المنتج</th>
                                        <th>القسم</th>
                                        <th>الكمية</th>
                                        <th>السعر</th>
                                        <th>الخصم</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(cart, i) in order.carts" :key="i">
                                        <td>{{ i + 1 }}</td>
                                        <td>{{ cart.productSizeName }}</td>
                                        <td class="pa-2">
                                            <v-avatar rounded>
                                                <img :src="cart.productImageUrl" alt="صورة المنتج " />
                                            </v-avatar>
                                        </td>
                                        <td>{{ cart.category }}</td>
                                        <td>{{ cart.qty }}</td>
                                        <td>
                                            {{ cart.price | formatCurrency }}
                                            د.ع
                                        </td>
                                        <td>{{ cart.discount }}%</td>
                                    </tr>
                                </tbody>
                            </v-simple-table>
                        </v-card>
                    </div>

                    <div class="pa-4">
                        <h4>
                            السعر الكلي :
                            {{ order.totalPrice | formatCurrency }} د.ع
                        </h4>
                        <h4 class="mt-1">
                            السعر الكلي بعد الخصم :
                            <span class="green--text">
                                {{ order.totalPriceAfterDiscount | formatCurrency }}
                                د.ع
                            </span>
                        </h4>
                    </div>

                    <v-card-actions>
                        <v-btn text @click="goBack()" color="red">
                            <h3>رجوع</h3>
                            <v-icon>keyboard_arrow_left</v-icon>
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-card>

            <v-dialog v-model="orderStatusDialog" width="50%" transition="dialog-top-transition">
                <v-card flat class="card">
                    <v-toolbar outlined flat color="grey lighten-4">
                        <v-toolbar-title>
                            تغير حالة الطلبية رقم
                            <strong>{{ order.orderNo }}</strong>
                            للزبون
                            <strong>{{ order.fromUserFullName }}</strong>
                        </v-toolbar-title>
                        <v-spacer />
                        <v-btn @click="close()" icon>
                            <v-icon>close</v-icon>
                        </v-btn>
                    </v-toolbar>

                    <v-form @submit.prevent="submit()" ref="form" lazy-validation v-model="valid">
                        <v-container>
                            <v-card outlined>
                                <v-container>
                                    <v-row no-gutters>
                                        <!-- states -->
                                        <v-col cols="12" md="12">
                                            <h4 class="mb-2">
                                                الحالة
                                                <span class="required">*</span>
                                            </h4>
                                            <v-chip-group
                                                column
                                                v-model="order.status"
                                                class="mr-n2"
                                            >
                                                <v-chip label :value="0" filter outlined>طلبية جديدة</v-chip>
                                                <v-chip label :value="1" filter outlined>قيد التصميم</v-chip>
                                                <v-chip label :value="2" filter outlined>قيد الطباعة</v-chip>
                                                <v-chip label :value="3" filter outlined>قيد التوصيل</v-chip>
                                                <v-chip label :value="4" filter outlined>منجزة</v-chip>
                                                <v-chip
                                                    label
                                                    :value="5"
                                                    filter
                                                    outlined
                                                >تم الغاء الطلبية</v-chip>
                                            </v-chip-group>
                                        </v-col>

                                        <!-- sendNotify -->
                                        <v-col cols="12" md="12">
                                            <v-checkbox
                                                color="info"
                                                v-model="item.sendNotify"
                                                label="إرسال إشعار إلى الزبون"
                                            ></v-checkbox>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-card>

                            <!-- actions -->
                            <v-col cols="12" md="12">
                                <v-row class="mt-2">
                                    <v-btn
                                        type="submit"
                                        :loading="$global.state.loading"
                                        x-large
                                        color="primary"
                                        class="btn secondary--text"
                                        :disabled="!valid || !order.status"
                                    >حفظ</v-btn>
                                    <v-btn
                                        @click="close()"
                                        x-large
                                        color="error"
                                        class="mr-2 btn"
                                        outlined
                                    >إلغاء</v-btn>
                                </v-row>
                            </v-col>
                        </v-container>
                    </v-form>
                </v-card>
            </v-dialog>
        </div>
    </div>
</template>

<script>
export default {
    props: ["id"],
    data() {
        return {
            valid: false,
            order: "",
            orderStatusDialog: false,
            item: {
                status: 1,
                sendNotify: true,
            },
        };
    },

    mounted() {
        this.getItem();
    },

    methods: {
        reset() {
            this.item = {
                status: 0,
                sendNotify: true,
            };
        },

        getItem() {
            this.$global.state.loading = true;
            this.$http
                .get("Orders/" + this.id)
                .then((res) => {
                    this.order = res.data.result;
                    console.log(this.order);
                })
                .catch((err) => {
                    this.$store.dispatch("toastification/setToast", {
                        message: `${err.data.message}`,
                        type: "error",
                    });
                })
                .finally(() => {
                    this.$global.state.loading = false;
                });
        },

        async submit() {
            this.$global.state.loading = true;
            this.item.status = this.order.status;
            await this.$http
                .put(
                    `${this.$route.meta.endPoint}/changeStatusOrder/${this.order.id}`,
                    this.item
                )
                .then((res) => {
                    this.close();
                    this.$store.dispatch("toastification/setToast", {
                        message: `تم تحديث حالة الطلبية بنجاح`,
                        type: "success",
                    });
                })
                .catch((err) => {
                    this.$store.dispatch("toastification/setToast", {
                        message: `${err.data.message}`,
                        type: "error",
                    });
                })
                .finally(() => (this.$global.state.loading = false));
        },

        async startChat() {
            // await this.createChat(this.order.fromUserId)
            this.$router.push({ path: `/chats`});
        },

        async createChat (clientId) {
            try {
                await this.$global.dispatch(`getChats`);
                const res = await this.$http.post(`Chats/${clientId}`);
                this.$eventBus.$emit(`refreshChats`, res.data.result);
            } catch (err) {
                this.$store.dispatch("toastification/setToast", {
                    message: `${err.data.message}`,
                    type: "error",
                });
            }
        },

        openInNewTab(src) {
            window.open(src, "_blank").focus();
        },

        close() {
            this.reset();
            this.resetValidation();
            this.orderStatusDialog = false;
        },

        resetValidation() {
            this.$refs.form.resetValidation();
        },

        goBack() {
            window.history.go(-1);
        },
    },
};
</script>


<style scoped>

.img__wrap {
    width: 150px;
    cursor: pointer;
}

.img__description {
    padding: 10px;
    position: absolute;
    top: 0;
    right: 0;
    background: rgba(18, 18, 18, 0.72);
    color: #fff;
    visibility: hidden;
    text-align: center;
    transition: opacity 0.2s, visibility 0.2s;
}

.img__wrap:hover .img__description {
    visibility: visible;
    opacity: 1;
}

</style>
